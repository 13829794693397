import React from 'react'
import team from '../images/abg.jpg'
import vision from '../images/vision.png'
import mision from '../images/mission.png'
import labor from '../images/labor.jpg'
import risk from '../images/risk.jpg'
import service from '../images/service.jpg'
import outsource from '../images/outsource.jpg'
import who from '../images/abgcons.jpg'
import cli1 from '../images/tata.png'
import cli2 from '../images/clientabg1.jpg'
import cli3 from '../images/clientabg2.png'
import cli4 from '../images/clientabg.jpg'
import 'animate.css'
import './Home.css'

export default function Home() {
  const containerStyle = {
    backgroundImage: `url(${team})`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
  };

  const headingStyle = {
    color: 'red', // Set the color of h3 to orange
    content:'top'
  };

  const cardData = [
    { title: "COMPREHENSIVE COVERAGE OF LABOR LAWS", image:cli1 },
    { title: "PAYROLL OUTSOURCING SERVICES", image:cli2 },
    { title: "STATUTORY COMPLIANCE SERVICES", image:cli3 },
    { title: "LEGAL CONSULTATION & OPINION", image:cli4 },
  ];

  return (
    <div style={{overflowX:"hidden", paddingTop:"60px"}}>
      <div style={{backgroundColor:"white"}}>
      <div  style={containerStyle}>
        <div className='div'>
        <h1 className="animate__animated  animate__zoomIn " style={headingStyle}>
        A<span className='text-dark'>BG</span> CONSULTANT
        </h1>
        <h2 className='animate__animated animate__pulse text-white'style={{fontSize:"30px"}}>Our Best Services Under</h2>
        <h3 style={{color:"black", fontSize:"22px"}}><i class="bi bi-check-circle-fill" style={{color:'white',fontSize:'18px',paddingRight:"5px"}}></i>Business Establishment Services</h3>
        <h3 style={{color:"black", fontSize:"22px"}}><i class="bi bi-check-circle-fill" style={{color:'white',fontSize:'18px',paddingRight:"5px"}}></i>Labour Law Compliance</h3>
        <h3 style={{color:"black", fontSize:"22px"}}><i class="bi bi-check-circle-fill" style={{color:'white',fontSize:'18px',paddingRight:"5px"}}></i>Accounting Services</h3>
        <h3 style={{color:"balck", fontSize:"22px"}}><i class="bi bi-check-circle-fill" style={{color:'white',fontSize:'18px',paddingRight:"5px"}}></i>Tax compliance</h3>
        <h3 style={{color:"black", fontSize:"22px"}}><i class="bi bi-check-circle-fill" style={{color:'white',fontSize:'18px',paddingRight:"5px"}}></i>Payroll processing</h3>
        <h3 style={{color:"black", fontSize:"22px"}}><i class="bi bi-check-circle-fill" style={{color:'white',fontSize:'18px',paddingRight:"5px"}}></i>IT Services</h3>
       </div>
       </div>

       {/* <div className='container' style={{display:"row"}}>
        <h1 style={{alignContent:'flex-start', fontFamily:'Montserrat', flexWrap:'wrap', position:'relative', width:'100%'}}>Our Mission</h1>
       <div className='vertical-line '></div>
        <p style={{textAlign:'center', fontFamily:'Montserrat', letterSpacing:'1px', fontSize:'17px', fontWeight:'400', lineHeight:'1.5em'}}>"Our Mission is to bring organizations together to motivate, inspire and 
          empower each other to ensure 100% statutory compliance they never thought were possible."</p>
       </div> */}
  <br/>
        <div  className ="d-md-flex" >
          <div>
           <img src={who} alt="img" className="about-image"></img>
          </div>
          <div  className="col-md animate__animated animate__fadeInRightBig">
            <h2 style={{textAlign:"center", fontFamily:'Montserrat'}}>Who We Are</h2>
            <p style={{padding:"20px",  fontstyle: 'inherit', letterSpacing:'1px', fontSize:'100%', fontWeight:'inherit', verticalAlign:'baseline', textAlign:'justify'}}><strong>ABG Consultant</strong> is the best enabling partner for your Statutory Compliance Services, focusing on 
          Labour Laws, Outsourcing, Payroll as well as providing Consultation &amp; Legal Opinion. We offer compliance services that perfectly meet your organisation’s requirements.<br/>
          It is absolutely imperative to comply with all legal and statutory norms for businesses to operate successfully in India. This often involves significant investment of resources,
             time, and continuous monitoring to be in the know of all the changes that are happening, in order to be compliant and avoid penalties.</p>
          </div>
       </div>
       <br/>
      
       {/* <div className='d-flex'  style={{ background:"azure"}}>
        <div>
        <h1 style={{textAlign:"center", color:'#234CA8', fontFamily:'Montserrat', fontSize:'25px'}}>WE ARE WITH</h1>
        <div style={{width:'50%', display:'flex', marginRight:'auto', position:'relative'}} >
        <img src={vision}  alt="img" width="40%" height="20%" style={{display:"flex"}}></img>
        <p style={{width:'100%', textAlign:'center', fontFamily:'Montserrat', fontSize:'15px', position:"relative", padding:"20px"}}>To make the most preferred alliance in the corporate
           world for optimizing the potential of Statutory Compliance.</p>
        </div>
        <div style={{width:'50%', display:'flex', marginLeft:'auto', position:'relative'}}>
        <img src={mision} alt="img"  width='40%' height="20%" ></img>
        <p>Our Mission is to bring organizations together to <strong>motivate, </strong><strong>inspire </strong> 
           and <strong>empower</strong> each other to ensure 100% statutory compliance they never thought were possible.</p>
        </div>
       </div>
       </div> */}

<div className='d-flex' style={{ background: "white"}}>
<h1 style={{ textAlign: "center", color: '#1052e6', fontFamily: 'Montserrat', fontSize: '30px' }}>WE ARE WITH</h1>

  <div className='d-md-flex' style={{ width: '120%' }}>
    <div className="content-container">
      <div className="image-container">
        <img src={vision} alt="Vision"   ></img>
      </div>
      <div className="text-container">
        <p style={{ textAlign: 'center', fontFamily: 'Montserrat', fontSize: '16px', padding: "10px" }}>Our Vission is to make the most preferred alliance in the corporate world for optimizing the potential of Statutory Compliance.</p>
      </div>
    </div>
    <div className="content-container">
      <div className="image-container">
        <img src={mision} alt="Mission" ></img>
      </div>
      <div className="text-container">
        <p style={{ textAlign: 'center', fontFamily: 'Montserrat', fontSize: '16px', padding: "10px" }}>Our Mission is to bring organizations together to <strong style={{color:"red"}}>motivate, inspire </strong> and <strong style={{color:"red"}}>empower</strong> each other to ensure 100% statutory compliance they never thought were possible.</p>
      </div>
    </div>
  </div>
</div>
{/* <br/> */}

       {/* <div >
        <h1  style={{textAlign:"center", fontFamily:'Montserrat'}}>Our Clients</h1>
        <div className='line'></div>
          <div className="mt-5 mb-5 card-container d-sm-flex" >
           {cardData.map((card, index) => (
           <div key={index} className={`card ${index === 1 ? 'highlight' : ''}`}>
            <img src={card.image} alt="Avatar" className="avatar" />
            <div className="container">
              <h5><b>{card.title}</b></h5>
              <p>{card.tag}</p>
            </div>
           </div>
          ))}
        </div>
       </div> */}
      </div>
    </div>
  )
}
