import React, { useState } from "react";
// import compservice from "../images/compservice.jpg";
import "./Services.css";
import s1 from "../images/services1.jpg";
import s2 from "../images/services2.png";
import s3 from "../images/services3.jpg";
import s4 from "../images/services4.jpg";
import s5 from "../images/services5.jpg";
import s6 from "../images/services6.jpg";
import s7 from "../images/services7.png";
import s8 from "../images/services8.png";
import "bootstrap/dist/css/bootstrap.min.css";
import { Collapse, Button } from "react-bootstrap";

export default function Services() {
  const [open, setOpen] = useState(true);
  return (
    <div style={{ overflowX: "hidden", paddingTop: "60px" }}>
      <div style={{ backgroundColor: "white" }}>
        <div>
          <h1
            style={{
              textAlign: "center",
              paddingTop: "40px",
              fontSize: "60px",
            }}
          >
            Our Services
          </h1>
          <div className="p-5 d-md-flex w-100">
            {/* <div>
              <img src={compservice} alt="Home" className="about-image" />
            </div> */}

            <div className=" about-text">
              {/* <h2 style={{color:"#146C64"}}>#Who We Are</h2> */}
              {/* <strong style={{color:"#014aad"}}>#Who We Are</strong><br/> */}
              <div className="d-md-flex">
                <div>
                  <p className="p-2 sec_p">
                    <strong style={{ color: "#014aad", fontSize: "25px" }}>
                      I. Business Establishment Services:
                    </strong>{" "}
                    <br></br>Looking forward to start a business? Wondering
                    which forms of business organization is suitable for your
                    business? We at ABG offer a full range of management
                    resources to help get your new company registered in India.
                    The choice depends on factors like, flexibility, funding,
                    nature of business, risk factors, taxation etc and ABG
                    advises you to choose the right form after understanding the
                    relevant factors from you. <br />
                    <strong style={{ color: "#014aad" }}>
                      ABG helps you register the following form of business
                      organizations:
                    </strong>{" "}
                    <br />
                    ➢ UDYAM Registration (MSME)
                    <br />
                    ➢ Partnership Firm Registration <br />
                    ➢ Private Limited Companies
                    <br />
                    ➢ Society Registration
                    <br />
                    ➢ Proprietorship Firm Registration
                    <br />
                    <strong>
                      Once business entity is registered, it has to obtain
                      various other registrations, based on its nature of
                      business. ABG provides the services of obtaining below
                      mentioned post incorporation registrations:
                    </strong>
                    <br />
                    ➢ Obtain PAN and TAN
                    <br />
                    ➢ GST Registration
                    <br />
                    ➢ PT Registration
                    <br />
                    ➢ PF and ESIC Registration
                    <br />
                    ➢ Shops & Commercial Establishment Registration
                    <br />
                    ➢ Factory Registration
                    <br />
                    ➢ Labour Welfare Fund (LWF) Registration
                    <br />➢ Contract registration/License.
                  </p>
                </div>
                <div>
                  <img className="s_imgR" src={s1}></img>
                </div>
              </div>
              <div className="d-md-flex">
                <div>
                  <img className="s_imgL" src={s2}></img>
                </div>
                <div>
                  <p className="p-2 sec_p">
                    {/* <br /> */}
                    <strong style={{ color: "#014aad", fontSize: "25px" }}>
                      II. Labour Law Compliance:
                    </strong>{" "}
                    <br />
                    There are various registers to be maintained and various
                    filings that are required to be done under the Labour law.{" "}
                    <br />
                    <strong style={{ color: "#014aad" }}>
                      Labour Law
                    </strong>{" "}
                    <br />
                    <ul style={{ justifyContent: "center" }}>
                      <li>
                        {" "}
                        Preparation and Filing of PF & ESI returns and other
                        forms with PF Department on any queries.{" "}
                      </li>
                      <li>
                        Preparation and filing of returns and forms under other
                        applicable labour laws.
                      </li>
                      <li>
                        {" "}
                        Maintenance of various registers under the various
                        labour laws
                      </li>
                      <li>Licensing/Registration under the labour Laws</li>
                      <li>
                        Prepare/draft replies for any inspection / show cause
                        notice that is received from Statutory bodies and
                        towards providing the closure report.
                      </li>
                      <li>
                        Implementation of client SOP to adhere compliances by
                        contractors’ decision making by vetting them as per
                        requisite Labour Laws.
                      </li>
                      <li>
                        Repository of Compliance database like monthly Statutory
                        registers, Statutory remittances Compliance
                      </li>
                      <li>
                        Representing client Inspections/ hearings, and liaison
                        with statutory authority.
                      </li>
                      <li>
                        Any non-compliance which results due to
                        inaction/non-adherence of advised as given by the
                        consultant it will correct by the consultant from the
                        defaulters.
                      </li>
                    </ul>
                    <div
                      className="accordion accordion-flush"
                      id="accordionFlushExample"
                    >
                      <p className="p-2 sec_p">
                      <div className="accordion-item" >
                        <h2 className="accordion-header" id="flush-headingOne">
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#flush-collapseOne"
                            aria-expanded="false"
                            aria-controls="flush-collapseOne"
                          >
                            <strong style={{ color: "#014aad" }}>
                              {" "}
                              The following Labour Laws / Acts /Rules shall be
                              covered for Compliance Assurance.
                            </strong>{" "}
                          </button>
                        </h2>
                        <div
                          id="flush-collapseOne"
                          class="accordion-collapse collapse"
                          aria-labelledby="flush-headingOne"
                          data-bs-parent="#accordionFlushExample"
                        >
                          <div className="accordion-body">
                            {" "}
                            <ul>
                              <li>
                                {" "}
                                The Shops & Commercial Establishment Act, 1958
                              </li>
                              <li> The Factories Act 1948</li>
                              <li>
                                The Employees Provident Fund and Miscellaneous
                                Provisions Act, 1952
                              </li>
                              <li>The Employees Compensation Act, 1923 </li>
                              <li>The Payment of Wages Act, 1936</li>
                              <li>The Minimum Wages Act, 1948</li>
                              <li>The Employees State Insurance Act, 1948</li>
                              <li>The Equal Remuneration Act,1976</li>
                              <li>The Payment of Bonus Act, 1965</li>
                              <li>The Payment of Gratuity Act 1972.</li>
                              <li>The Maternity Benefit Act, 1961</li>
                              <li>The Employment Exchange Act, 1959</li>
                              <li>
                                The Industrial Establishments (National and
                                Festival Holidays) Act, 1965
                              </li>
                              <li>
                                The Contract Labour (Regulation & Abolition)
                                Act, 1970
                              </li>
                              <li>
                                The Child & Adolescent Labour (Prohibition and
                                Regulation) Act’1986
                              </li>
                              <li>
                                The Industrial Employment (Standing Orders) Act
                                1946
                              </li>
                              <li>The Industrial Disputes Act 1947 </li>
                              <li>
                                The Labour Welfare Fund Act, (State act as
                                applicable)
                              </li>
                              <li>
                                The Professional Tax Act (State Act as
                                applicable)
                              </li>
                              <li>
                                The Sexual Harassment of Women at Workplace
                                (Prevention, Prohibition & Redressal) POSH Act
                                2013
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      </p>
                    </div>
                    <br />
                  </p>
                </div>
              </div>
              <div className="d-md-flex">
                <div>
                  <p className=" p-2 sec_p">
                    <br />
                    <strong style={{ color: "#014aad", fontSize: "25px" }}>
                      III. Accounting Services:
                    </strong>{" "}
                    <br />
                    <strong style={{ color: "#014aad" }}>
                      Why Outsource Accounting & Payroll Services?
                    </strong>{" "}
                    <br />
                    For Small businesses, hiring a full-time accountant could be
                    a costly affair. Also, having a junior inexperienced
                    accountant comes with a risk of not being compliant with all
                    statutory requirement, finding a replacement in case of
                    resignation of an accountant is a troublesome task. Junior
                    accountant will find it difficult to support on higher end
                    tasks like tax planning, structuring employee pay package,
                    meeting investor requirements, Meeting bank document
                    requirements, input on taxation and other laws etc. A
                    smaller business also may not have enough work to keep an
                    accountant engaged full time.
                    <br />
                    <strong style={{ color: "#014aad" }}>
                      ABG provides Accounting and Compliance Services, which
                      includes the following:
                    </strong>{" "}
                    <br />
                    <strong style={{ color: "#014aad" }}>
                      ➢ Accounting / Bookkeeping:
                    </strong>{" "}
                    <br />
                    We do Daily, Monthly, Quarterly Bookkeeping Services
                    depending on the size, volume, and nature of company’s
                    business activity and as per the client requirements. We
                    provide both on-site and off-site accounting services. We
                    support the cloud as well as desktop-based accounting
                    software.
                    <br />
                    <strong style={{ color: "#014aad" }}>
                      ➢ Accounting Services Includes
                    </strong>{" "}
                    <br />
                    <ul>
                      <li> Bank reconciliations.</li>
                      <li>
                        {" "}
                        Preparing Financial Statements as per Indian GAAP.
                      </li>
                      <li>
                        {" "}
                        Raising of Invoices and Accounting for Invoices raised
                        and receipts from Customers.
                      </li>
                      <li>
                        {" "}
                        Verification of Vendor Bills and its payment after
                        obtaining approvals after making necessary deductions.{" "}
                      </li>
                      <li>
                        {" "}
                        Accounting for all the expenses, Purchase of assets,
                        Monthly Payments, etc.{" "}
                      </li>
                      <li>
                        {" "}
                        Preparation of Monthly Management Information System
                        Report.{" "}
                      </li>
                      <li>
                        {" "}
                        Budgeting and Cost vs Budget analysis and reporting.{" "}
                      </li>
                      <li>
                        {" "}
                        Preparing Financial Statements as per Indian GAAP.
                      </li>
                    </ul>
                  </p>
                </div>
                <div>
                  <img className="s_imgR" src={s3}></img>
                </div>
              </div>
              <div className="d-md-flex">
                <div>
                  <img className="s_imgL" src={s4}></img>
                </div>
                <div>
                  <p className="p-2 sec_p">
                    <br />
                    <strong style={{ color: "#014aad", fontSize: "25px" }}>
                      IV. Tax compliance:
                    </strong>{" "}
                    <br />
                    Compliance with Tax law is a very important aspect of the
                    Finance Department. We keep ourselves constantly updated
                    with the changes in tax laws and give the highest priority
                    to comply with all the filing requirements within the
                    relevant due dates and provide expert advice on tax matters.
                    <br />
                    <strong style={{ color: "#014aad" }}>
                      Services include:
                    </strong>{" "}
                    <br />
                    <ul>
                      <strong style={{ color: "#014aad" }}>➢ Income Tax</strong>{" "}
                      <li>Computation of Monthly TDS and remittance. </li>
                      <li>
                        {" "}
                        Preparation and filing of Quarterly e-TDS returns of the
                        Company and sharing TDS Certificates with vendors.{" "}
                      </li>
                      <li>
                        {" "}
                        Estimation of Advance Tax of the Company and remittance
                        of the same.{" "}
                      </li>
                      <li>
                        {" "}
                        Consultancy services on matters concerning Income Tax of
                        the Company.
                      </li>
                      <br />
                      <strong style={{ color: "#014aad" }}>➢ GST</strong>{" "}
                      <li>
                        Computation of Monthly GST liability and remittance.{" "}
                      </li>
                      <li>
                        {" "}
                        Filing of Monthly & Quarterly, Annual GST Returns of the
                        Company.{" "}
                      </li>
                      <li>
                        {" "}
                        Application for GST Refund and Co-ordination with
                        Department.{" "}
                      </li>
                      <li>
                        {" "}
                        Consultancy services on matters concerning the GST Tax
                        of the Company.
                      </li>
                      <br />
                      <strong style={{ color: "#014aad" }}>
                        ➢ Professional Tax
                      </strong>{" "}
                      <li>
                        Payment of Monthly PT and filing of Monthly return.{" "}
                      </li>
                      <li>Preparation and filing of Annual return. </li>
                      <li>
                        {" "}
                        Payment and filing of Annual PT for the Company.{" "}
                      </li>
                    </ul>
                  </p>
                </div>
              </div>
              <br />
              <div className="d-md-flex">
                <div>
                  <p className="p-2 sec_p">
                    <br />
                    <strong style={{ color: "#014aad", fontSize: "25px" }}>
                      V. Payroll processing:
                    </strong>{" "}
                    <br />
                    Processing of payroll has a very important finance function
                    due to the tax compliance involved in the processing of
                    payroll.
                    <br />
                    <strong style={{ color: "#014aad" }}>
                      Payroll processing includes:
                    </strong>
                    <br />
                    <ul>
                      <li>
                        Obtaining Income Tax Declaration Forms from employees.{" "}
                      </li>
                      <li> Advising on the Structuring CTC. </li>
                      <li>
                        {" "}
                        Preparation of Monthly Pay Roll register and advice to
                        the Company for the amount to be paid, and execution of
                        payment, Payslip generation.{" "}
                      </li>
                      <li>
                        {" "}
                        Advising employees on uploading / Collection of
                        Investment proofs on Annual basis and verification of
                        investment proofs.{" "}
                      </li>
                      <li> Clarifying employee on the tax matters. </li>
                      <li>
                        {" "}
                        Preparation and filing of Quarterly e-TDS returns for
                        the employees of the Company.{" "}
                      </li>
                      <li>
                        {" "}
                        Generation of Form 16 of the employees of the Company on
                        an annual basis.
                      </li>
                    </ul>
                  </p>
                  <p className=" p-2 sec_p">
                    <strong style={{ color: "#014aad", fontSize: "25px" }}>
                      VI. IT Services:
                    </strong>{" "}
                    <br />
                    <ul>
                      <li>Website Development.</li>
                      <li>Website Maintenance.</li>
                      <li>Domain Services.</li>
                      <li>Hosting.</li>
                      <li>SEO and Digital Marketing.</li>
                    </ul>
                  </p>
                </div>
                <div>
                  {/* <img className="s_imgR" src={s8}></img> */}
                  <img className="s_imgR" src={s7}></img>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
