import React from "react";
// import aboutus from "../images/aboutus.jpg";
import about from "../images/abgcons.jpg";
import "./Aboutus.css";

export default function Aboutus() {
  // const containerStyle = {
  //   backgroundImage: `url(${aboutus})`,
  //   backgroundPosition: "center",
  //   backgroundSize: "cover",
  //   backgroundRepeat: "no-repeat"
  // };

  // const headingStyle = {
  //   color: "orange", 
  //   content: "top",
  // };

  return (
    <div style={{ overflowX: "hidden", paddingTop: "80px" }}>
      <div style={{ backgroundColor: "white" }}>
        {/* <div style={containerStyle}>
          <div className="div">
          <h1
            class="animate__animated animate__backInLeft "
            style={headingStyle}
          >
            <strong>At ABG Consultant, we are committed to delivering exceptional HR services that drive your business forward. Our goal is to be your trusted partner, providing the support and expertise you need to succeed in today’s competitive business environment.</strong>
          </h1>
          </div>
        </div> */}
        

        <div>
          <h1 style={{ textAlign: "center", paddingTop: "30px" }}>About Us</h1>
          <div className="p-5 d-lg-flex ">
            <div>
              <img src={about} alt="Home" className="about-images" />
            </div>

            <div className=" about-text">
              {/* <h2 style={{color:"#146C64"}}>#Who We Are</h2> */}
              {/* <strong style={{color:"#014aad"}}>#Who We Are</strong><br/> */}
              <p className="p-2" style={{ textAlign: "justify" }}>
                <strong style={{ color: "#014aad" }}>
                  At ABG Consultant,
                </strong>{" "}
                <br></br>we are dedicated to providing comprehensive HR
                solutions that empower businesses to thrive. With a wealth of
                experience and a deep understanding of the HR landscape, we
                offer a range of services designed to meet the diverse needs of
                our clients. Our expertise{" "}
                <strong style={{ color: "#1b7dff", fontWeight: "normal" }}>
                  {" "}
                  spans recruitment, outsourcing, payroll, statutory compliance,
                  and legal advice,
                </strong>{" "}
                ensuring that we can support your organization at every stage of
                its growth.
                <br />
                <strong style={{ color: "#014aad" }}>Recruitment:</strong>{" "}
                <br />
                Our recruitment services are tailored to connect you with top
                talent. We understand the importance of finding the right fit
                for your organization, and our experienced team is committed to
                sourcing, screening, and selecting candidates who align with
                your company’s values and goals. Whether you need to fill a
                single position or build an entire team, we have the resources
                and expertise to deliver.<br/>
                <strong style={{ color: "#014aad" }}>Outsourcing:</strong>{" "}
                <br />
                Outsourcing your HR functions to ABG Consultant allows you to
                focus on your core business activities while we manage your HR
                operations efficiently. Our outsourcing solutions cover a wide
                range of HR tasks, including employee administration, benefits
                management, and performance management. We provide scalable
                solutions that can be customized to fit the unique needs of your
                business.<br/>
                <strong style={{ color: "#014aad" }}>Payroll:</strong> <br />
                Managing payroll can be a complex and time-consuming task. Our
                payroll services ensure that your employees are paid accurately
                and on time, every time. We handle all aspects of payroll
                processing, including tax calculations, deductions, and
                compliance with local regulations. By partnering with us, you
                can rest assured that your payroll processes are in safe hands.<br/>
                <strong style={{ color: "#014aad" }}>
                  Statutory Compliance and Legal Advice:
                </strong>{" "}
                <br />
                Navigating the complexities of statutory compliance can be
                challenging for any organization. ABG Consultant offers
                comprehensive services to ensure that your business complies
                with all relevant laws and regulations. Our team of experts
                provides guidance on statutory compliance and licensing, helping
                you to avoid legal pitfalls and maintain a compliant workplace.
                We offer advice on labor laws, health and safety regulations,
                and other statutory requirements, ensuring that your business
                remains in good standing with regulatory authorities.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
